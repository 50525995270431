import { Controller } from "@hotwired/stimulus"

const VIDEO_ASPECT_RATIO = 9 / 16;
const SLIDER_MAX_WIDTH_RATIO = 0.8;
const SLIDER_NAVIGATION_SIZE = 30;
const WINDOW_HEIGHT_OFFSET = 20;

// Connects to data-controller="videos-manager"
export default class extends Controller {
  static targets = ["header", "footer", "slider", "slide", "downloadLink"];

  connect() {
    this.activeSlideChanged = this.activeSlideChanged.bind(this);
    this.adjustSliderHeight = this.adjustSliderHeight.bind(this);

    this.initialization = true;

    window.addEventListener("resize", this.adjustSliderHeight);

    this.adjustSliderHeight();
  }

  disconnect() {
    if (this.swiper) {
      this.swiper.off("slideChange", this.activeSlideChanged);
    }

    window.removeEventListener("resize", this.adjustSliderHeight);
  }

  slideTargetConnected(slide) {
    setTimeout(() => this.updateSlideVideoPlayingState(), 0);

    slide.addEventListener("player:ready", this.updateSlideVideoPlayingState);
  }

  sliderTargetConnected() {
    setTimeout(() => this.initCarousel(), 0);
  }

  headerTargetConnected() {
    this.adjustSliderHeight();
  }

  footerTargetConnected() {
    this.adjustSliderHeight();
  }

  downloadLinkTargetConnected() {
    this.updateDownloadLinkState();
  }

  initCarousel() {
    this.swiper = this.application.getControllerForElementAndIdentifier(
      this.sliderTarget, "carousel"
    ).swiper;

    this.swiper.on("slideChange", this.activeSlideChanged);

    this.activeSlideChanged();
    this.adjustSliderHeight();

    setTimeout(() => this.displayElements(), 0);

    this.initialization = false;
  }

  displayElements() {
    this.sliderTarget.classList.remove("uninitialized");
    this.footerTarget.classList.remove("uninitialized");
  }

  activeSlideChanged() {
    this.activeSlide = this.slideTargets[this.swiper.activeIndex];

    this.updateDownloadLinkState();

    if (this.initialization) return;

    // On the initial state of the carousel, the video player is not ready and the 
    // autoplay value should be respected, avoiding issues with media loading when calling
    // the play() method of the current slide's video player.
    this.updateSlideVideoPlayingState();
  }

  updateDownloadLinkState() {
    if (!this.activeSlide || !this.hasDownloadLinkTarget) return;

    const { downloadUrl } = this.activeSlide.dataset;

    const downloadLinkHref = downloadUrl || "javascript:void(0)";
    this.downloadLinkTarget.href = downloadLinkHref;

    const disabled = !downloadUrl;
    this.downloadLinkTarget.classList.toggle("disabled", disabled);
    this.downloadLinkTarget.setAttribute("aria-disabled", disabled);
    this.downloadLinkTarget.setAttribute("disabled", disabled);
  }

  updateSlideVideoPlayingState() {
    if (!this.activeSlide) return;

    this.slideTargets.forEach((slide) => {
      const videoPlayer = slide.querySelector("video");

      if (!videoPlayer) return;
      
      const isVideoPlaying = 
        videoPlayer.currentTime > 0 && 
        !videoPlayer.paused && 
        !videoPlayer.ended && 
        videoPlayer.readyState > 2;

      if (slide.id === this.activeSlide.id && !isVideoPlaying) {
        setTimeout(() => videoPlayer.play(), 0);
      } else if (slide.id !== this.activeSlide.id && isVideoPlaying) {
        setTimeout(() => videoPlayer.pause(), 0);
      }
    });
  }

  adjustSliderHeight() {
    if (!this.swiper || !this.hasHeaderTarget || !this.hasFooterTarget) return;

    const [width, height] = this.calculateSliderWidthAndHeight();

    this.sliderTarget.style.width = `${width}px`;
    this.sliderTarget.style.height = `${height}px`;

    this.swiper.update();
  }

  calculateSliderWidthAndHeight() {
    const { innerWidth: windowWidth, innerHeight: windowHeight } = window;

    const maxWidth = windowWidth * SLIDER_MAX_WIDTH_RATIO;
    const maxHeight =
      windowHeight - 
      this.headerTarget.offsetHeight - 
      this.footerTarget.offsetHeight - 
      SLIDER_NAVIGATION_SIZE - 
      WINDOW_HEIGHT_OFFSET;

    if (maxHeight > maxWidth / VIDEO_ASPECT_RATIO) {
      return [maxWidth, maxWidth / VIDEO_ASPECT_RATIO];
    } else {
      return [maxHeight * VIDEO_ASPECT_RATIO, maxHeight];
    }
  }
}
