import { Controller } from "@hotwired/stimulus"
import Hls from "hls.js";

// Connects to data-controller="video-player"
export default class extends Controller {
  static values = { 
    sourceUrl: String
  }

  connect() {
    if (this.player) return;

    this.element.addEventListener("loadeddata", () => this.handleLoadedData());

    this.player = new Hls();

    this.player.on(Hls.Events.MANIFEST_PARSED, () => {
      this.element.dispatchEvent(new CustomEvent("player:ready"));
    });

    this.player.loadSource(this.sourceUrlValue);
    this.player.attachMedia(this.element);
  }

  handleLoadedData() {
    this.element.classList.add("is-loaded");
  }
}
